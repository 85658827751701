import React, { useEffect, useState } from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
} from '../components/Tabs';
import { LogoCrest } from '../components/Logo';
import SEO from '../components/Seo';

interface Props {
  location: {
    state?: {
      tab?: string;
    };
  };
}

export default function DegreeRequirementsPage({ location: { state } }: Props) {
  const [defaultTab, setDefaultTab] = useState<number | string>(
    state?.tab || 0
  );

  useEffect(() => {
    setDefaultTab(state?.tab || 0);
  }, [state]);

  return (
    <>
      <SEO
        title="Degree Requirements"
        description="Requirements in courses, course work, and time to complete the degree."
      />
      <main className="page-degree-requirements">
        <section className="container section-m-t page-m-b crest-bg-wrapper">
          <div className="crest-bg">
            <LogoCrest />
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <h2>Degree Requirements</h2>
              <Tabs id="degree-requirements" defaultTab={defaultTab}>
                <TabList>
                  <Tab id="courses">
                    <span>Courses</span>
                  </Tab>
                  <Tab id="course-work">
                    <span>Course Work</span>
                  </Tab>
                  <Tab id="time">
                    <span>Time</span>
                  </Tab>
                </TabList>
                <TabPanelWrapper>
                  <TabPanel>
                    <ul className="bullets">
                      <li>
                        The Master of Arts in Theology is awarded upon the
                        completion of twelve courses and a comprehensive
                        examination.
                      </li>
                      <li>
                        <strong>Eight</strong> of the courses are required, and{' '}
                        <strong>four</strong> are electives.
                      </li>
                      <li>
                        Students may use one of their four electives to write a
                        master’s thesis.
                      </li>
                      <li>
                        Full-time students may complete the degree in four
                        semesters over two academic years.
                      </li>
                      <li>
                        Part-time students typically complete the program in
                        four years.
                      </li>
                    </ul>
                  </TabPanel>
                  <TabPanel>
                    <ul className="bullets">
                      <li>
                        Throughout the course of a semester, students attend
                        three hours of lecture per week and can expect weekly
                        assignments which include a brief discussion board post
                        or a short quiz, along with substantive reading
                        assignments.
                      </li>
                      <li>
                        Students may also expect to write three essays of 4-6
                        pages in length through the course of a semester or a
                        longer term paper of 12-15 pages in length at the end of
                        the semester.
                      </li>
                      <li>
                        Midterm and final exams consist of objective questions
                        and/or essays prompts, depending upon the preference of
                        the professor.
                      </li>
                    </ul>
                  </TabPanel>
                  <TabPanel>
                    <ul className="bullets">
                      <li>
                        Standard for a master’s level program, we recommend our
                        students commit 9-12 hours each week to one course.
                      </li>
                      <li>
                        This includes three hours of lecture plus time taken
                        outside of class on weekly assignments, readings,
                        essays, and exams. Of course, these hours may fluctuate
                        with deadlines and exam preparation.
                      </li>
                    </ul>
                  </TabPanel>
                </TabPanelWrapper>
              </Tabs>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
